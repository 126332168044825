.react-web--maxsports__container {
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  line-height: inherit;
  margin: 0;
}

.react-web--maxsports__container *, .react-web--maxsports__container :before, .react-web--maxsports__container :after {
  box-sizing: border-box;
}

.react-web--maxsports__container hr {
  height: 0;
  color: inherit;
}

.react-web--maxsports__container abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

.react-web--maxsports__container b, .react-web--maxsports__container strong {
  font-weight: bolder;
}

.react-web--maxsports__container code, .react-web--maxsports__container kbd, .react-web--maxsports__container samp, .react-web--maxsports__container pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

.react-web--maxsports__container small {
  font-size: 80%;
}

.react-web--maxsports__container sub, .react-web--maxsports__container sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

.react-web--maxsports__container sub {
  bottom: -.25em;
}

.react-web--maxsports__container sup {
  top: -.5em;
}

.react-web--maxsports__container table {
  text-indent: 0;
  border-color: inherit;
}

.react-web--maxsports__container button, .react-web--maxsports__container input, .react-web--maxsports__container optgroup, .react-web--maxsports__container select, .react-web--maxsports__container textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

.react-web--maxsports__container button, .react-web--maxsports__container select {
  text-transform: none;
}

.react-web--maxsports__container button, .react-web--maxsports__container [type="button"], .react-web--maxsports__container [type="reset"], .react-web--maxsports__container [type="submit"] {
  -webkit-appearance: button;
}

.react-web--maxsports__container ::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.react-web--maxsports__container :-moz-focusring {
  outline: 1px dotted ButtonText;
}

.react-web--maxsports__container :-moz-ui-invalid {
  box-shadow: none;
}

.react-web--maxsports__container legend {
  padding: 0;
}

.react-web--maxsports__container progress {
  vertical-align: baseline;
}

.react-web--maxsports__container ::-webkit-inner-spin-button, .react-web--maxsports__container ::-webkit-outer-spin-button {
  height: auto;
}

.react-web--maxsports__container [type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

.react-web--maxsports__container ::-webkit-search-decoration {
  -webkit-appearance: none;
}

.react-web--maxsports__container ::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.react-web--maxsports__container summary {
  display: list-item;
}

.react-web--maxsports__container blockquote, .react-web--maxsports__container dl, .react-web--maxsports__container dd, .react-web--maxsports__container h1, .react-web--maxsports__container h2, .react-web--maxsports__container h3, .react-web--maxsports__container h4, .react-web--maxsports__container h5, .react-web--maxsports__container h6, .react-web--maxsports__container hr, .react-web--maxsports__container figure, .react-web--maxsports__container p, .react-web--maxsports__container pre {
  margin: 0;
}

.react-web--maxsports__container button {
  background-color: #0000;
  background-image: none;
}

.react-web--maxsports__container fieldset {
  margin: 0;
  padding: 0;
}

.react-web--maxsports__container ol, .react-web--maxsports__container ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.react-web--maxsports__container *, .react-web--maxsports__container :before, .react-web--maxsports__container :after {
  box-sizing: border-box;
  border: 0 solid;
}

.react-web--maxsports__container hr {
  border-top-width: 1px;
}

.react-web--maxsports__container img {
  border-style: solid;
}

.react-web--maxsports__container textarea {
  resize: vertical;
}

.react-web--maxsports__container input::placeholder, .react-web--maxsports__container textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

.react-web--maxsports__container button, .react-web--maxsports__container [role="button"] {
  cursor: pointer;
}

.react-web--maxsports__container table {
  border-collapse: collapse;
}

.react-web--maxsports__container h1, .react-web--maxsports__container h2, .react-web--maxsports__container h3, .react-web--maxsports__container h4, .react-web--maxsports__container h5, .react-web--maxsports__container h6 {
  font-size: inherit;
  font-weight: inherit;
}

.react-web--maxsports__container a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

.react-web--maxsports__container button, .react-web--maxsports__container input, .react-web--maxsports__container optgroup, .react-web--maxsports__container select, .react-web--maxsports__container textarea {
  line-height: inherit;
  color: inherit;
  padding: 0;
}

.react-web--maxsports__container pre, .react-web--maxsports__container code, .react-web--maxsports__container kbd, .react-web--maxsports__container samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.react-web--maxsports__container img, .react-web--maxsports__container svg, .react-web--maxsports__container video, .react-web--maxsports__container canvas, .react-web--maxsports__container audio, .react-web--maxsports__container iframe, .react-web--maxsports__container embed, .react-web--maxsports__container object {
  vertical-align: middle;
  display: block;
}

.react-web--maxsports__container img, .react-web--maxsports__container video {
  max-width: 100%;
  height: auto;
}

.react-web--maxsports__container *, .react-web--maxsports__container :before, .react-web--maxsports__container :after {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.react-web--maxsports__container .pointer-events-none {
  pointer-events: none;
}

.react-web--maxsports__container .pointer-events-auto {
  pointer-events: auto;
}

.react-web--maxsports__container .visible {
  visibility: visible;
}

.react-web--maxsports__container .invisible {
  visibility: hidden;
}

.react-web--maxsports__container .static {
  position: static;
}

.react-web--maxsports__container .fixed {
  position: fixed;
}

.react-web--maxsports__container .absolute {
  position: absolute;
}

.react-web--maxsports__container .relative {
  position: relative;
}

.react-web--maxsports__container .inset-0 {
  inset: 0;
}

.react-web--maxsports__container .inset-x-0 {
  left: 0;
  right: 0;
}

.react-web--maxsports__container .bottom-0 {
  bottom: 0;
}

.react-web--maxsports__container .left-1\/2 {
  left: 50%;
}

.react-web--maxsports__container .left-2 {
  left: .5rem;
}

.react-web--maxsports__container .left-4 {
  left: 1rem;
}

.react-web--maxsports__container .left-\[19px\] {
  left: 19px;
}

.react-web--maxsports__container .right-2 {
  right: .5rem;
}

.react-web--maxsports__container .right-4 {
  right: 1rem;
}

.react-web--maxsports__container .top-0 {
  top: 0;
}

.react-web--maxsports__container .top-1\/2 {
  top: 50%;
}

.react-web--maxsports__container .top-2 {
  top: .5rem;
}

.react-web--maxsports__container .top-\[40px\] {
  top: 40px;
}

.react-web--maxsports__container .z-0 {
  z-index: 0;
}

.react-web--maxsports__container .z-10 {
  z-index: 10;
}

.react-web--maxsports__container .z-40 {
  z-index: 40;
}

.react-web--maxsports__container .z-50 {
  z-index: 50;
}

.react-web--maxsports__container .z-\[10000\] {
  z-index: 10000;
}

.react-web--maxsports__container .m-0 {
  margin: 0;
}

.react-web--maxsports__container .-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.react-web--maxsports__container .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.react-web--maxsports__container .my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.react-web--maxsports__container .my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.react-web--maxsports__container .-mr-3 {
  margin-right: -.75rem;
}

.react-web--maxsports__container .-mr-4 {
  margin-right: -1rem;
}

.react-web--maxsports__container .mb-1 {
  margin-bottom: .25rem;
}

.react-web--maxsports__container .mb-1\.5 {
  margin-bottom: .375rem;
}

.react-web--maxsports__container .mb-2 {
  margin-bottom: .5rem;
}

.react-web--maxsports__container .mb-2\.5 {
  margin-bottom: .625rem;
}

.react-web--maxsports__container .mb-3 {
  margin-bottom: .75rem;
}

.react-web--maxsports__container .mb-4 {
  margin-bottom: 1rem;
}

.react-web--maxsports__container .mb-5 {
  margin-bottom: 1.25rem;
}

.react-web--maxsports__container .mb-8 {
  margin-bottom: 2rem;
}

.react-web--maxsports__container .ml-0 {
  margin-left: 0;
}

.react-web--maxsports__container .ml-0\.5 {
  margin-left: .125rem;
}

.react-web--maxsports__container .ml-1 {
  margin-left: .25rem;
}

.react-web--maxsports__container .ml-2 {
  margin-left: .5rem;
}

.react-web--maxsports__container .ml-3 {
  margin-left: .75rem;
}

.react-web--maxsports__container .ml-5 {
  margin-left: 1.25rem;
}

.react-web--maxsports__container .ml-6 {
  margin-left: 1.5rem;
}

.react-web--maxsports__container .ml-9 {
  margin-left: 2.25rem;
}

.react-web--maxsports__container .ml-auto {
  margin-left: auto;
}

.react-web--maxsports__container .mr-1 {
  margin-right: .25rem;
}

.react-web--maxsports__container .mr-2 {
  margin-right: .5rem;
}

.react-web--maxsports__container .mr-2\.5 {
  margin-right: .625rem;
}

.react-web--maxsports__container .mt-1 {
  margin-top: .25rem;
}

.react-web--maxsports__container .mt-2 {
  margin-top: .5rem;
}

.react-web--maxsports__container .mt-2\.5 {
  margin-top: .625rem;
}

.react-web--maxsports__container .mt-3 {
  margin-top: .75rem;
}

.react-web--maxsports__container .mt-4 {
  margin-top: 1rem;
}

.react-web--maxsports__container .mt-5 {
  margin-top: 1.25rem;
}

.react-web--maxsports__container .mt-6 {
  margin-top: 1.5rem;
}

.react-web--maxsports__container .mt-8 {
  margin-top: 2rem;
}

.react-web--maxsports__container .mt-px {
  margin-top: 1px;
}

.react-web--maxsports__container .box-content {
  box-sizing: content-box;
}

.react-web--maxsports__container .block {
  display: block;
}

.react-web--maxsports__container .inline-block {
  display: inline-block;
}

.react-web--maxsports__container .inline {
  display: inline;
}

.react-web--maxsports__container .flex {
  display: flex;
}

.react-web--maxsports__container .inline-flex {
  display: inline-flex;
}

.react-web--maxsports__container .hidden {
  display: none;
}

.react-web--maxsports__container .h-0 {
  height: 0;
}

.react-web--maxsports__container .h-0\.5 {
  height: .125rem;
}

.react-web--maxsports__container .h-1 {
  height: .25rem;
}

.react-web--maxsports__container .h-10 {
  height: 2.5rem;
}

.react-web--maxsports__container .h-4 {
  height: 1rem;
}

.react-web--maxsports__container .h-5 {
  height: 1.25rem;
}

.react-web--maxsports__container .h-56 {
  height: 14rem;
}

.react-web--maxsports__container .h-6 {
  height: 1.5rem;
}

.react-web--maxsports__container .h-7 {
  height: 1.75rem;
}

.react-web--maxsports__container .h-72 {
  height: 18rem;
}

.react-web--maxsports__container .h-\[351px\] {
  height: 351px;
}

.react-web--maxsports__container .h-full {
  height: 100%;
}

.react-web--maxsports__container .h-screen {
  height: 100vh;
}

.react-web--maxsports__container .max-h-72 {
  max-height: 18rem;
}

.react-web--maxsports__container .max-h-\[300px\] {
  max-height: 300px;
}

.react-web--maxsports__container .max-h-\[90vh\] {
  max-height: 90vh;
}

.react-web--maxsports__container .min-h-\[667px\] {
  min-height: 667px;
}

.react-web--maxsports__container .min-h-full {
  min-height: 100%;
}

.react-web--maxsports__container .w-1 {
  width: .25rem;
}

.react-web--maxsports__container .w-10 {
  width: 2.5rem;
}

.react-web--maxsports__container .w-11 {
  width: 2.75rem;
}

.react-web--maxsports__container .w-32 {
  width: 8rem;
}

.react-web--maxsports__container .w-4 {
  width: 1rem;
}

.react-web--maxsports__container .w-5 {
  width: 1.25rem;
}

.react-web--maxsports__container .w-64 {
  width: 16rem;
}

.react-web--maxsports__container .w-7 {
  width: 1.75rem;
}

.react-web--maxsports__container .w-72 {
  width: 18rem;
}

.react-web--maxsports__container .w-8 {
  width: 2rem;
}

.react-web--maxsports__container .w-\[300px\] {
  width: 300px;
}

.react-web--maxsports__container .w-\[377px\] {
  width: 377px;
}

.react-web--maxsports__container .w-\[49\%\] {
  width: 49%;
}

.react-web--maxsports__container .w-full {
  width: 100%;
}

.react-web--maxsports__container .\!max-w-none {
  max-width: none !important;
}

.react-web--maxsports__container .max-w-2xl {
  max-width: 42rem;
}

.react-web--maxsports__container .max-w-3xl {
  max-width: 48rem;
}

.react-web--maxsports__container .max-w-52 {
  max-width: 13rem;
}

.react-web--maxsports__container .max-w-\[280px\] {
  max-width: 280px;
}

.react-web--maxsports__container .max-w-lg {
  max-width: 32rem;
}

.react-web--maxsports__container .max-w-md {
  max-width: 28rem;
}

.react-web--maxsports__container .max-w-prose {
  max-width: 65ch;
}

.react-web--maxsports__container .max-w-xs {
  max-width: 20rem;
}

.react-web--maxsports__container .flex-1 {
  flex: 1;
}

.react-web--maxsports__container .flex-shrink-0 {
  flex-shrink: 0;
}

.react-web--maxsports__container .flex-grow-0 {
  flex-grow: 0;
}

.react-web--maxsports__container .grow {
  flex-grow: 1;
}

.react-web--maxsports__container .-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.react-web--maxsports__container .-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.react-web--maxsports__container .-translate-y-full {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.react-web--maxsports__container .translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.react-web--maxsports__container .translate-x-5 {
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.react-web--maxsports__container .scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.react-web--maxsports__container .scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.react-web--maxsports__container .transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.react-web--maxsports__container .transform-gpu {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes border-radius {
  0% {
    border-radius: 40px;
  }

  100% {
    border-radius: 8px 0 8px 8px;
  }
}

.react-web--maxsports__container .animate-border-radius {
  animation: .15s forwards border-radius;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.react-web--maxsports__container .animate-fade-in {
  animation: .2s 50ms forwards fade-in;
}

@keyframes message {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.react-web--maxsports__container .animate-message {
  animation: .2s 50ms forwards message;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.react-web--maxsports__container .animate-spin {
  animation: 1s linear infinite spin;
}

@keyframes typing-dot {
  0% {
    transform: translateY(0);
  }

  28% {
    transform: translateY(-7px);
  }

  44% {
    transform: translateY(0);
  }
}

.react-web--maxsports__container .animate-typing-dot {
  animation: 1s ease-in-out infinite typing-dot;
}

.react-web--maxsports__container .cursor-auto {
  cursor: auto;
}

.react-web--maxsports__container .cursor-grab {
  cursor: grab;
}

.react-web--maxsports__container .cursor-pointer {
  cursor: pointer;
}

.react-web--maxsports__container .cursor-zoom-in {
  cursor: zoom-in;
}

.react-web--maxsports__container .cursor-zoom-out {
  cursor: zoom-out;
}

.react-web--maxsports__container .touch-none {
  touch-action: none;
}

.react-web--maxsports__container .resize {
  resize: both;
}

.react-web--maxsports__container .list-decimal {
  list-style-type: decimal;
}

.react-web--maxsports__container .list-disc {
  list-style-type: disc;
}

.react-web--maxsports__container .flex-row {
  flex-direction: row;
}

.react-web--maxsports__container .flex-col {
  flex-direction: column;
}

.react-web--maxsports__container .flex-nowrap {
  flex-wrap: nowrap;
}

.react-web--maxsports__container .items-end {
  align-items: flex-end;
}

.react-web--maxsports__container .items-center {
  align-items: center;
}

.react-web--maxsports__container .justify-end {
  justify-content: flex-end;
}

.react-web--maxsports__container .justify-center {
  justify-content: center;
}

.react-web--maxsports__container .justify-between {
  justify-content: space-between;
}

.react-web--maxsports__container .self-start {
  align-self: flex-start;
}

.react-web--maxsports__container .self-center {
  align-self: center;
}

.react-web--maxsports__container .overflow-auto {
  overflow: auto;
}

.react-web--maxsports__container .overflow-hidden {
  overflow: hidden;
}

.react-web--maxsports__container .overflow-x-auto {
  overflow-x: auto;
}

.react-web--maxsports__container .overflow-y-auto {
  overflow-y: auto;
}

.react-web--maxsports__container .overflow-y-hidden {
  overflow-y: hidden;
}

.react-web--maxsports__container .scroll-smooth {
  scroll-behavior: smooth;
}

.react-web--maxsports__container .truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-web--maxsports__container .rounded {
  border-radius: .25rem;
}

.react-web--maxsports__container .rounded-full {
  border-radius: 9999px;
}

.react-web--maxsports__container .rounded-lg {
  border-radius: .5rem;
}

.react-web--maxsports__container .rounded-sm {
  border-radius: .125rem;
}

.react-web--maxsports__container .rounded-tl-none {
  border-top-left-radius: 0;
}

.react-web--maxsports__container .border {
  border-width: 1px;
}

.react-web--maxsports__container .border-2 {
  border-width: 2px;
}

.react-web--maxsports__container .border-b {
  border-bottom-width: 1px;
}

.react-web--maxsports__container .border-t {
  border-top-width: 1px;
}

.react-web--maxsports__container .border-crayola {
  --tw-border-opacity: 1;
  border-color: rgb(109 107 220 / var(--tw-border-opacity));
}

.react-web--maxsports__container .border-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgb(249 250 251 / var(--tw-border-opacity));
}

.react-web--maxsports__container .border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity));
}

.react-web--maxsports__container .border-pelorous {
  --tw-border-opacity: 1;
  border-color: rgb(62 161 201 / var(--tw-border-opacity));
}

.react-web--maxsports__container .border-persianRed {
  --tw-border-opacity: 1;
  border-color: rgb(199 54 46 / var(--tw-border-opacity));
}

.react-web--maxsports__container .border-stone-200\/40 {
  border-color: #e7e5e466;
}

.react-web--maxsports__container .border-transparent {
  border-color: #0000;
}

.react-web--maxsports__container .border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.react-web--maxsports__container .bg-black\/90 {
  background-color: #000000e6;
}

.react-web--maxsports__container .bg-crayola {
  --tw-bg-opacity: 1;
  background-color: rgb(109 107 220 / var(--tw-bg-opacity));
}

.react-web--maxsports__container .bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.react-web--maxsports__container .bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.react-web--maxsports__container .bg-gray-50\/60 {
  background-color: #f9fafb99;
}

.react-web--maxsports__container .bg-gray-50\/90 {
  background-color: #f9fafbe6;
}

.react-web--maxsports__container .bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.react-web--maxsports__container .bg-mirage {
  --tw-bg-opacity: 1;
  background-color: rgb(33 32 68 / var(--tw-bg-opacity));
}

.react-web--maxsports__container .bg-mirage\/60 {
  background-color: #21204499;
}

.react-web--maxsports__container .bg-pampas {
  --tw-bg-opacity: 1;
  background-color: rgb(246 245 243 / var(--tw-bg-opacity));
}

.react-web--maxsports__container .bg-pelorous {
  --tw-bg-opacity: 1;
  background-color: rgb(62 161 201 / var(--tw-bg-opacity));
}

.react-web--maxsports__container .bg-pelorous\/30 {
  background-color: #3ea1c94d;
}

.react-web--maxsports__container .bg-stone-200\/40 {
  background-color: #e7e5e466;
}

.react-web--maxsports__container .bg-transparent {
  background-color: #0000;
}

.react-web--maxsports__container .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.react-web--maxsports__container .bg-white\/80 {
  background-color: #fffc;
}

.react-web--maxsports__container .bg-opacity-10 {
  --tw-bg-opacity: .1;
}

.react-web--maxsports__container .bg-opacity-90 {
  --tw-bg-opacity: .9;
}

.react-web--maxsports__container .bg-gradientPrimary {
  background-image: linear-gradient(193.8deg, #fffbf800 20.45%, #17162f99 74.05%);
}

.react-web--maxsports__container .object-cover {
  object-fit: cover;
}

.react-web--maxsports__container .p-0 {
  padding: 0;
}

.react-web--maxsports__container .p-3 {
  padding: .75rem;
}

.react-web--maxsports__container .p-4 {
  padding: 1rem;
}

.react-web--maxsports__container .p-6 {
  padding: 1.5rem;
}

.react-web--maxsports__container .p-7 {
  padding: 1.75rem;
}

.react-web--maxsports__container .px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.react-web--maxsports__container .px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.react-web--maxsports__container .px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.react-web--maxsports__container .px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.react-web--maxsports__container .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.react-web--maxsports__container .px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.react-web--maxsports__container .px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.react-web--maxsports__container .py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.react-web--maxsports__container .py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.react-web--maxsports__container .py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.react-web--maxsports__container .py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.react-web--maxsports__container .py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.react-web--maxsports__container .py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.react-web--maxsports__container .py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.react-web--maxsports__container .py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.react-web--maxsports__container .py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.react-web--maxsports__container .pb-2 {
  padding-bottom: .5rem;
}

.react-web--maxsports__container .pb-4 {
  padding-bottom: 1rem;
}

.react-web--maxsports__container .pb-6 {
  padding-bottom: 1.5rem;
}

.react-web--maxsports__container .pr-1 {
  padding-right: .25rem;
}

.react-web--maxsports__container .pt-10 {
  padding-top: 2.5rem;
}

.react-web--maxsports__container .pt-14 {
  padding-top: 3.5rem;
}

.react-web--maxsports__container .pt-2 {
  padding-top: .5rem;
}

.react-web--maxsports__container .pt-20 {
  padding-top: 5rem;
}

.react-web--maxsports__container .pt-6 {
  padding-top: 1.5rem;
}

.react-web--maxsports__container .pt-px {
  padding-top: 1px;
}

.react-web--maxsports__container .text-left {
  text-align: left;
}

.react-web--maxsports__container .text-center {
  text-align: center;
}

.react-web--maxsports__container .text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.react-web--maxsports__container .text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.react-web--maxsports__container .text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.react-web--maxsports__container .text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.react-web--maxsports__container .text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.react-web--maxsports__container .text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.react-web--maxsports__container .text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.react-web--maxsports__container .text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.react-web--maxsports__container .font-bold {
  font-weight: 700;
}

.react-web--maxsports__container .font-medium {
  font-weight: 500;
}

.react-web--maxsports__container .font-normal {
  font-weight: 400;
}

.react-web--maxsports__container .font-semibold {
  font-weight: 600;
}

.react-web--maxsports__container .uppercase {
  text-transform: uppercase;
}

.react-web--maxsports__container .leading-\[20px\] {
  line-height: 20px;
}

.react-web--maxsports__container .tracking-tight {
  letter-spacing: -.025em;
}

.react-web--maxsports__container .tracking-wider {
  letter-spacing: .05em;
}

.react-web--maxsports__container .text-darkMirage {
  --tw-text-opacity: 1;
  color: rgb(23 22 47 / var(--tw-text-opacity));
}

.react-web--maxsports__container .text-gray-50 {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

.react-web--maxsports__container .text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.react-web--maxsports__container .text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.react-web--maxsports__container .text-mirage {
  --tw-text-opacity: 1;
  color: rgb(33 32 68 / var(--tw-text-opacity));
}

.react-web--maxsports__container .text-pelorous {
  --tw-text-opacity: 1;
  color: rgb(62 161 201 / var(--tw-text-opacity));
}

.react-web--maxsports__container .text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.react-web--maxsports__container .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.react-web--maxsports__container .underline {
  text-decoration-line: underline;
}

.react-web--maxsports__container .opacity-0 {
  opacity: 0;
}

.react-web--maxsports__container .opacity-100 {
  opacity: 1;
}

.react-web--maxsports__container .opacity-25 {
  opacity: .25;
}

.react-web--maxsports__container .opacity-30 {
  opacity: .3;
}

.react-web--maxsports__container .opacity-50 {
  opacity: .5;
}

.react-web--maxsports__container .opacity-75 {
  opacity: .75;
}

.react-web--maxsports__container .opacity-90 {
  opacity: .9;
}

.react-web--maxsports__container .shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.react-web--maxsports__container .shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.react-web--maxsports__container .shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.react-web--maxsports__container .outline {
  outline-style: solid;
}

.react-web--maxsports__container .ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.react-web--maxsports__container .blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.react-web--maxsports__container .drop-shadow-avatar {
  --tw-drop-shadow: drop-shadow(0px 3px 3px #00000040);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.react-web--maxsports__container .filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.react-web--maxsports__container .backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.react-web--maxsports__container .backdrop-opacity-80 {
  --tw-backdrop-opacity: opacity(.8);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.react-web--maxsports__container .backdrop-filter {
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.react-web--maxsports__container .transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.react-web--maxsports__container .transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.react-web--maxsports__container .transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.react-web--maxsports__container .duration-200 {
  transition-duration: .2s;
}

.react-web--maxsports__container .duration-300 {
  transition-duration: .3s;
}

.react-web--maxsports__container .ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.react-web--maxsports__container .ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.react-web--maxsports__container .ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.react-web--maxsports__container .animate-delay-200 {
  animation-delay: .2s;
}

.react-web--maxsports__container .animate-delay-300 {
  animation-delay: .3s;
}

.react-web--maxsports__container .animate-delay-400 {
  animation-delay: .4s;
}

.react-web--maxsports__container .last\:mb-0:last-child {
  margin-bottom: 0;
}

.react-web--maxsports__container .last\:mr-0:last-child {
  margin-right: 0;
}

.react-web--maxsports__container .last\:mr-6:last-child {
  margin-right: 1.5rem;
}

.react-web--maxsports__container .last\:pr-2:last-child {
  padding-right: .5rem;
}

.react-web--maxsports__container .hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.react-web--maxsports__container .hover\:bg-pelorous:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(62 161 201 / var(--tw-bg-opacity));
}

.react-web--maxsports__container .hover\:text-gray-50:hover {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

.react-web--maxsports__container .focus\:border-white:focus {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.react-web--maxsports__container .focus\:bg-pelorous:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(62 161 201 / var(--tw-bg-opacity));
}

.react-web--maxsports__container .focus\:text-white:focus {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.react-web--maxsports__container .focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.react-web--maxsports__container .focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.react-web--maxsports__container .focus\:ring-pelorous:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(62 161 201 / var(--tw-ring-opacity));
}

.react-web--maxsports__container .focus\:ring-offset-1:focus {
  --tw-ring-offset-width: 1px;
}

.react-web--maxsports__container .focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

@media (width >= 768px) {
  .react-web--maxsports__container .md\:mt-2 {
    margin-top: .5rem;
  }

  .react-web--maxsports__container .md\:block {
    display: block;
  }

  .react-web--maxsports__container .md\:max-h-\[60\%\] {
    max-height: 60%;
  }

  .react-web--maxsports__container .md\:w-60 {
    width: 15rem;
  }

  .react-web--maxsports__container .md\:max-w-none {
    max-width: none;
  }

  .react-web--maxsports__container .md\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .react-web--maxsports__container .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .react-web--maxsports__container .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .react-web--maxsports__container .md\:font-medium {
    font-weight: 500;
  }
}

@media (width >= 1024px) {
  .react-web--maxsports__container .lg\:order-1 {
    order: 1;
  }

  .react-web--maxsports__container .lg\:order-last {
    order: 9999;
  }

  .react-web--maxsports__container .lg\:m-0 {
    margin: 0;
  }

  .react-web--maxsports__container .lg\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .react-web--maxsports__container .lg\:-mr-1 {
    margin-right: -.25rem;
  }

  .react-web--maxsports__container .lg\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .react-web--maxsports__container .lg\:block {
    display: block;
  }

  .react-web--maxsports__container .lg\:hidden {
    display: none;
  }

  .react-web--maxsports__container .lg\:h-\[561px\] {
    height: 561px;
  }

  .react-web--maxsports__container .lg\:max-h-none {
    max-height: none;
  }

  .react-web--maxsports__container .lg\:w-1\/2 {
    width: 50%;
  }

  .react-web--maxsports__container .lg\:w-116 {
    width: 29rem;
  }

  .react-web--maxsports__container .lg\:w-40 {
    width: 10rem;
  }

  .react-web--maxsports__container .lg\:w-44 {
    width: 11rem;
  }

  .react-web--maxsports__container .lg\:w-96 {
    width: 24rem;
  }

  .react-web--maxsports__container .lg\:w-\[602px\] {
    width: 602px;
  }

  .react-web--maxsports__container .lg\:w-auto {
    width: auto;
  }

  .react-web--maxsports__container .lg\:max-w-2xl {
    max-width: 42rem;
  }

  .react-web--maxsports__container .lg\:max-w-4xl {
    max-width: 56rem;
  }

  .react-web--maxsports__container .lg\:max-w-lg {
    max-width: 32rem;
  }

  .react-web--maxsports__container .lg\:max-w-none {
    max-width: none;
  }

  .react-web--maxsports__container .lg\:max-w-xl {
    max-width: 36rem;
  }

  .react-web--maxsports__container .lg\:max-w-xs {
    max-width: 20rem;
  }

  .react-web--maxsports__container .lg\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .react-web--maxsports__container .lg\:transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .react-web--maxsports__container .lg\:flex-row {
    flex-direction: row;
  }

  .react-web--maxsports__container .lg\:items-center {
    align-items: center;
  }

  .react-web--maxsports__container .lg\:self-end {
    align-self: flex-end;
  }

  .react-web--maxsports__container .lg\:self-center {
    align-self: center;
  }

  .react-web--maxsports__container .lg\:overflow-hidden {
    overflow: hidden;
  }

  .react-web--maxsports__container .lg\:p-11 {
    padding: 2.75rem;
  }

  .react-web--maxsports__container .lg\:p-8 {
    padding: 2rem;
  }

  .react-web--maxsports__container .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .react-web--maxsports__container .lg\:pb-16 {
    padding-bottom: 4rem;
  }

  .react-web--maxsports__container .lg\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .react-web--maxsports__container .lg\:pl-16 {
    padding-left: 4rem;
  }

  .react-web--maxsports__container .lg\:pr-8 {
    padding-right: 2rem;
  }

  .react-web--maxsports__container .lg\:pt-8 {
    padding-top: 2rem;
  }

  .react-web--maxsports__container .lg\:text-left {
    text-align: left;
  }

  .react-web--maxsports__container .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .react-web--maxsports__container .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .react-web--maxsports__container .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .react-web--maxsports__container .lg\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  .react-web--maxsports__container .lg\:tracking-widest {
    letter-spacing: .1em;
  }

  .react-web--maxsports__container .lg\:transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .react-web--maxsports__container .lg\:duration-500 {
    transition-duration: .5s;
  }

  .react-web--maxsports__container .lg\:ease-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .react-web--maxsports__container .lg\:last\:mr-5:last-child {
    margin-right: 1.25rem;
  }

  .react-web--maxsports__container .lg\:last\:pr-4:last-child {
    padding-right: 1rem;
  }

  .react-web--maxsports__container .lg\:hover\:-translate-y-1:hover {
    --tw-translate-y: -.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .react-web--maxsports__container .lg\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

