@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.react-web-app--maxsports__container {
  width: 100%;
  height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
